import FormattedField from "../../../molecules/Form/Fields/FormattedField";
import TextField from "../../../molecules/Form/Fields/TextField";
import { AmountField, OptionsField } from "./Inputs";
import { reasons } from "../../../../utils/select-options";
import TextAreaField from "../../../molecules/Form/Fields/TextArea";

export const formInit = () => {
  return {
    email: { value: "", message: "", required: true },
    requestedLoan: { value: "", message: "", required: true },
    reason: { value: reasons[0].value, message: "", required: true },
    ssn: { value: "", message: "", required: true },
    phoneNumber: { value: "", message: "", required: true },
    mockData: { value: "", message: "", required: false },
  };
};

export const renderFields = (form) => [
  {
    value: form.requestedLoan.value,
    name: "requestedLoan",
    placeholder: "Loan Amount",
    message: form.requestedLoan.message,
    thousandSeparator: true,
    component: AmountField,
  },
  {
    value: form.reason.value,
    name: "reason",
    placeholder: "Select a reason",
    message: form.reason.message,
    thousandSeparator: true,
    component: OptionsField,
    options: reasons,
  },
];

export const renderCredentialsFields = (form) => [
  {
    value: form.ssn.value,
    name: "ssn",
    placeholder: "SSN",
    message: form.ssn.message,
    thousandSeparator: true,
    component: FormattedField,
    mask: "_",
    format: "#########",
  },
  {
    value: form.email.value,
    name: "email",
    placeholder: "Email",
    message: form.email.message,
    thousandSeparator: true,
    component: TextField,
  },
  {
    value: form?.phoneNumber?.value,
    name: "phoneNumber",
    placeholder: "(***) *** ****",
    message: form?.phoneNumber?.message || "",
    component: FormattedField,
    format: "(###) ### ####",
    mask: "_",
    autocomplete: "off",
  },
];

export const renderMock = (form) => [
  {
    value: form.mockData.value,
    name: "mockData",
    placeholder: "Mock json data",
    message: form.mockData.message,
    component: TextAreaField,
  },
];
